import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import type { Post } from "../../common/post.model"
import { POST_TYPES } from "../../common/post-type.model"

type PostCardProps = {
  post: Post
  size?: string
}

const PostCard = (props: PostCardProps) => {
  const image = getImage(props.post.image)
  if (!image) {
    return <></>
  }
  const size = props.size
  let containerSizes = ""
  let imageSizes = ""
  switch (size) {
    case "mobile-large":
      // large for mobiles and xl screens and smaller for in between (used for search)
      imageSizes = "w-60 h-60"
      containerSizes = "w-60 h-90"
      break
    default:
      // large only for xl screens and smaller for smaller screens (used for sliders)
      imageSizes = "w-60 h-60"
      containerSizes = "w-60 h-90"
      break
  }
  let borderColor = ""
  switch (props.post?.postType?.typeId) {
    case POST_TYPES.SUHBAH:
    case POST_TYPES.ENSEIGNEMENT:
      borderColor = "border-yellow-500"
      break
    case POST_TYPES.ARTICLE:
    case POST_TYPES.SITE_MISC:
      borderColor = "border-blue-500"
      break
    default:
      borderColor = "border-blue-500"
      break
  }
  const cityDetails = props.post.city?.split(",")
  const city = cityDetails?.length ? cityDetails[0] : ""
  return (
    <Link to={`/posts/${props.post.slug}`}>
      <div
        className={`cursor-pointer shadow-md hover:shadow-lg active:shadow-sm ${containerSizes}`}
        style={{ borderTopLeftRadius: `50% 30%`, borderTopRightRadius: `50% 30%` }}
      >
        <div className="h-full flex flex-col">
          <GatsbyImage
            className={`${imageSizes} flex-shrink-0 bg-gray-100 border-t-2 ${borderColor}`}
            imgClassName="w-full h-full"
            draggable="false"
            image={image}
            alt={props.post.title}
            style={{ borderTopLeftRadius: `50% 30%`, borderTopRightRadius: `50% 30%` }}
          />
          <div className="p-2 bg-white h-full">
            <div className="text-rose-700 text-xs flex flex-row">
              <div>{props.post.publishedDate}</div>
              <div className="ml-auto">{city}</div>
            </div>
            <div className="max-lines-3 lg:mt-1">
              {props.post.category ? (
                <span className="text-rose-700 font-semibold">{props.post.category.name}.&nbsp;</span>
              ) : (
                <></>
              )}
              <span className="text-gray-700">{props.post.title}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default PostCard
