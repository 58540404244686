import React, { MouseEvent, useCallback, useEffect, useRef, useState } from "react"

import PostCard from "../cards/post-card"
import Heading from "../form/heading"
import type { Post } from "../../common/post.model"
import { isBrowser } from "../../utils/utils"

const POSTS_PER_PAGE = 12

type SearchResultProps = {
  posts: Post[]
  onClearSearch: (e: MouseEvent<HTMLElement>) => void
  resultsTitle?: String
}

const SearchResult = (props: SearchResultProps) => {
  const [currentPosts, setCurrentPosts] = useState<Post[]>([])
  // TODO: review types / logic
  const observer: any = useRef()
  const headerSearchRef = useRef<HTMLDivElement>(null)

  const [reachedResHeader, setReachedResHeader] = useState<boolean>(false)
  const [searchHeaderPos, setSearchHeaderPos] = useState<number>()

  useEffect(() => {
    if (isBrowser) {
      const handleScroll = () => {
        const position = window.pageYOffset
        if (searchHeaderPos) {
          setReachedResHeader(position >= searchHeaderPos)
        }
      }
      let currentSearchHeaderPos = searchHeaderPos
      if (!searchHeaderPos && !reachedResHeader && headerSearchRef && headerSearchRef.current) {
        const viewportOffset = headerSearchRef.current.getBoundingClientRect()
        currentSearchHeaderPos = viewportOffset.top + window.scrollY - 70
        setSearchHeaderPos(currentSearchHeaderPos)
      }
      window.addEventListener("scroll", handleScroll, { passive: true })

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    } else {
      return () => {}
    }
  }, [searchHeaderPos, reachedResHeader])

  useEffect(() => {
    setCurrentPosts((props.posts || []).slice(0, POSTS_PER_PAGE))
  }, [props.posts])
  const lastPostElementRef = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && props.posts.length > currentPosts.length) {
          setCurrentPosts((props.posts || []).slice(0, currentPosts.length - 1 + POSTS_PER_PAGE))
        }
      })
      if (node) observer.current.observe(node)
    },
    [props.posts, currentPosts]
  )
  return (
    <div>
      <div className={`${reachedResHeader ? "fixed py-4 top-14 w-full z-10 bg-white border-b-2 border-gray-100" : ""}`}>
        <div ref={headerSearchRef} className="flex flex-row items-center">
          <button
            className="rounded-full p-3 shadow-md hover:shadow-lg active:shadow-sm text-gray-700 border hover:bg-gray-50 outline-none focus:outline-none"
            onClick={props.onClearSearch}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <div className="ml-4">
            <Heading>{props.resultsTitle ? props.resultsTitle : "Posts trouvés:"}</Heading>
          </div>
        </div>
      </div>
      <div className="py-4 sm:py-8">
        {!!props.posts?.length ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-8 justify-items-center">
            {currentPosts.map((post, index) => {
              if (currentPosts.length === index + 1) {
                return (
                  <div key={`res-${index}`} ref={lastPostElementRef}>
                    <PostCard post={post} size="mobile-large"></PostCard>
                  </div>
                )
              }
              return (
                <div key={`res-${index}`}>
                  <PostCard post={post} size="mobile-large"></PostCard>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="flex justify-center text-gray-700 pt-8 sm:pt-16">
            <div className="flex flex-col">
              <div className="mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-20 w-20"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
              </div>
              <div className="font-semibold text-2xl mt-4">Pas de résultats...</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchResult
