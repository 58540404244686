import React, { ReactNode } from "react"

type HeadingProps = {
  children: ReactNode
  color?: string
}

const Heading = (props: HeadingProps) => {
  let colorClass = ""
  switch (props.color) {
    case "pink":
      colorClass = "text-pink-700"
      break
    case "yellow":
      colorClass = "text-yellow-500"
      break
    case "blue":
      colorClass = "text-blue-500"
      break
    case "green":
      colorClass = "text-green-700"
      break
    default:
      colorClass = "text-blue-500"
      break
  }
  return <div className={`${colorClass} font-semibold text-2xl sm:text-3xl`}>{props.children}</div>
}

export default Heading
