import React, { MouseEvent, ReactNode } from "react"
import Button from "../form/button"
import Heading from "../form/heading"

type SliderHeaderProps = {
  text: string
  buttonText: string
  buttonSize?: string
  color: string
  onSeeMoreClick: (e: MouseEvent<HTMLElement>) => void
}

const SliderHeader = (props: SliderHeaderProps) => {
  let buttonSize = props.buttonSize
  if (!buttonSize) {
    buttonSize = "large"
  }
  return (
    <div className="flex flex-col md:flex-row-reverse">
      <div className="ml-auto">
        <Button onButtonClick={props.onSeeMoreClick} color="green" size={buttonSize}>
          {props.buttonText}
        </Button>
      </div>
      <div className="mt-4 md:mt-0">
        <Heading color={props.color}>{props.text}</Heading>
      </div>
    </div>
  )
}
export default SliderHeader
