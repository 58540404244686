import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

type ImageCardProps = {
  static: "tariqa"
}

const ImageCard = (props: ImageCardProps) => {
  let containerSizes = "w-60 h-90"
  let imageSizes = "w-60 h-90"
  let borderColor = "border-yellow-500"
  let image = ""
  let alt = ""
  let link = "/adab-de-la-tariqa"
  const getStaticImage = () => {
    switch (props.static) {
      case "tariqa":
        link = "/adab-de-la-tariqa"
        return (
          <StaticImage
            className={`${imageSizes} flex-shrink-0 bg-gray-100 border-t-2 ${borderColor}`}
            imgClassName="w-full h-full"
            draggable="false"
            src="../../images/adab-tariqa.png"
            alt="tariqa"
            style={{ borderTopLeftRadius: `50% 30%`, borderTopRightRadius: `50% 30%` }}
          />
        )
    }
  }
  console.log("link: ", link)
  return (
    <Link to={link}>
      <div
        className={`cursor-pointer shadow-md hover:shadow-lg active:shadow-sm ${containerSizes}`}
        style={{ borderTopLeftRadius: `50% 30%`, borderTopRightRadius: `50% 30%` }}
      >
        <div className="h-full flex flex-col">{getStaticImage()}</div>
      </div>
    </Link>
  )
}

export default ImageCard
