import React from "react"
import type { Post } from "../../common/post.model"

import PostCard from "../cards/post-card"
import { isBrowser } from "../../utils/utils"
import ImageCard from "../cards/image-card"

type SliderProps = {
  posts: Post[]
  imageLinks?: { static: string }[]
}

class Slider extends React.Component<SliderProps, {}> {
  divRef: any
  pos = { left: 0, x: 0 }

  constructor(props: SliderProps) {
    super(props)
    this.divRef = React.createRef()

    // this.mouseDownHandler = this.mouseDownHandler.bind(this)
    // this.mouseUpHandler = this.mouseUpHandler.bind(this)
    // this.mouseMoveHandler = this.mouseMoveHandler.bind(this)
  }
  componentWillUnmount() {
    // if (isBrowser) {
    //   document.removeEventListener("mousemove", this.mouseMoveHandler)
    //   document.removeEventListener("mouseup", this.mouseMoveHandler)
    // }
  }

  scroll = (scrollOffset: number) => {
    this.divRef.current.scrollLeft += scrollOffset
  }
  // mouseDownHandler = (e: any) => {
  //   // this.divRef.current.style.cursor = 'grabbing';
  //   this.pos = {
  //     // The current scroll
  //     left: this.divRef.current.scrollLeft,
  //     // Get the current mouse position
  //     x: e.clientX,
  //   }
  //   if (isBrowser) {
  //     document.addEventListener("mousemove", this.mouseMoveHandler)
  //     document.addEventListener("mouseup", this.mouseUpHandler)
  //   }
  // }

  // mouseUpHandler = (e: any) => {
  //   // this.divRef.current.style.cursor = 'default';
  //   if (isBrowser) {
  //     document.removeEventListener("mousemove", this.mouseMoveHandler)
  //     document.removeEventListener("mouseup", this.mouseUpHandler)
  //   }
  // }
  // mouseMoveHandler = (e: any) => {
  //   // How far the mouse has been moved
  //   const dx = e.clientX - this.pos.x

  //   // Scroll the element
  //   this.divRef.current.scrollLeft = this.pos.left - dx
  // }

  render() {
    const scrollSmooth: any = {
      scrollBehavior: "smooth",
    }
    const posts = []
    if (this.props.imageLinks) {
      posts.push(...this.props.imageLinks)
    }
    posts.push(...this.props.posts)
    return (
      <div className="relative">
        <div
          // onMouseDown={this.mouseDownHandler}
          ref={this.divRef}
          style={scrollSmooth}
          className="flex scrollbar-hide flex-nowrap overflow-x-scroll py-2 px-1"
        >
          {posts.map((post: any, index: number) => {
            if (post.slug) {
              return (
                <div
                  key={post.slug}
                  className={`m-auto ${index === 0 ? "pr-3" : index === posts.length - 1 ? "pl-3" : "px-3"}`}
                >
                  <PostCard post={post}></PostCard>
                </div>
              )
            } else {
              return (
                <div
                  key={post.static}
                  className={`m-auto ${index === 0 ? "pr-3" : index === posts.length - 1 ? "pl-3" : "px-3"}`}
                >
                  <ImageCard static={post.static}></ImageCard>
                </div>
              )
            }
          })}
        </div>
        {posts.length > 4 && (
          <div>
            <button
              className="hidden lg:block absolute top-40 -left-16 rounded-full p-3 text-gray-700 border border-blue-500 hover:bg-gray-50 hover:text-gray-900 outline-none focus:outline-none"
              onClick={() => this.scroll(-312)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              className="hidden lg:block absolute top-40 -right-16 rounded-full p-3 text-gray-700 border border-blue-500 hover:bg-gray-50 hover:text-gray-900 outline-none focus:outline-none"
              onClick={() => this.scroll(312)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        )}
      </div>
    )
  }
}

export default Slider
